<template>
  <div class="filter-container">
    <el-card>
      <el-input
        v-model="value.activity_id"
        class="filter-item"
        style="width: 150px"
        clearable
        placeholder="请输入专题页ID"
      ></el-input>
      <el-input
        v-model="value.name"
        class="filter-item"
        style="width: 150px"
        clearable
        placeholder="请输入专题名称"
      ></el-input>
      <!-- 创建人 -->
      <RemoteSelect v-model="value.creator" />
      <el-select
        style="width: 150px"
        class="filter-item"
        v-model="value.platform"
        clearable
        placeholder="专题页类型"
      >
        <el-option :key="1" label="H5" value="H5"></el-option>
      </el-select>
      <el-select
        style="width: 150px"
        class="filter-item"
        v-model="value.is_active"
        clearable
        placeholder="是否有效"
      >
        <el-option :key="1" label="是" :value="true"></el-option>
        <el-option :key="2" label="否" :value="false"></el-option>
      </el-select>
      <el-date-picker
        v-model="dateTimeRange"
        type="datetimerange"
        range-separator="至"
        start-placeholder="开始时间"
        end-placeholder="结束日期"
        :default-time="defaultTime"
        value-format="yyyy-MM-dd HH:mm:ss"
        clearable
        style="margin: 0 10px 10px 0; vertical-align: middle"
        @change="formatDataTime"
      ></el-date-picker>
      <el-button class="filter-item" type="primary" icon="el-icon-search" @click="doSearch"
        >搜索</el-button
      >
    </el-card>
  </div>
</template>

<script>
import _ from "lodash-es";
import RemoteSelect from "@/components/RemoteSelect";

export default {
  components: {
    RemoteSelect,
  },
  props: {
    value: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      dateTimeRange: [],
      defaultTime: ["00:00:00", "23:59:59"],
    };
  },
  created() {
    const { query } = this.$route;
    const { page, size, is_active, ...rest } = query;
    this.$emit("input", {
      ...rest,
      page: parseInt(page) || 1,
      size: parseInt(size) || 10,
      is_active: is_active === undefined ? undefined : Boolean(is_active),
    });
    this.dateTimeRange = [rest.startTime, rest.endTime];
  },
  methods: {
    doSearch() {
      const { path } = this.$route;
      this.$router
        .replace({
          path,
          query: _.omitBy({ ...this.value }, (value) => ["", undefined, null].includes(value)),
        })
        .catch(() => {});
      this.$emit("search");
    },
    formatDataTime(v) {
      if (v) {
        this.value.start_time = this.dateTimeRange[0];
        this.value.end_time = this.dateTimeRange[1];
      } else {
        this.value.start_time = "";
        this.value.end_time = "";
      }
    },
  },
};
</script>

<style></style>
