<template>
  <div class="app-container">
    <SearchForm v-model="queryParams" @search="fetchData" />
    <el-button
      v-permission="['zt.add_zt']"
      type="primary"
      icon="el-icon-plus"
      @click="$refs.edit.handleEdit()"
      style="margin-bottom: 10px"
      >添加</el-button
    >
    <el-card>
      <el-table
        v-loading.body="tableLoading"
        :data="tableData"
        element-loading-text="加载中 ..."
        border
        header-align="center"
        highlight-current-row
      >
        <el-table-column
          label="专题ID"
          min-width="80px"
          prop="activity_id"
          align="center"
          header-align="center"
        />
        <el-table-column
          label="专题名称"
          min-width="200"
          prop="name"
          align="center"
          header-align="center"
          show-overflow-tooltip
        />
        <el-table-column
          label="活动时间"
          min-width="310"
          align="center"
          :formatter="timeFormatter"
        />
        <el-table-column
          label="是否有效"
          min-width="80px"
          prop="is_active"
          align="center"
          header-align="center"
        >
          <template slot-scope="scope">
            <span class="warn" v-if="scope.row.is_active">有效</span>
            <span class="info" v-else>无效</span>
          </template>
        </el-table-column>

        <el-table-column
          label="链接"
          min-width="300"
          prop="link"
          header-align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <a :href="scope.row.link" target="_blank">{{ scope.row.link }}</a>
          </template>
        </el-table-column>

        <el-table-column
          label="类型"
          min-width="80px"
          prop="platform"
          align="center"
          header-align="center"
        />
        <el-table-column
          label="创建人"
          min-width="80px"
          prop="creator.name"
          align="center"
          header-align="center"
        />

        <el-table-column
          label="操作"
          min-width="300px"
          align="center"
          header-align="center"
          fixed="right"
        >
          <template slot-scope="scope">
            <el-button
              v-permission="['zt.design_zt']"
              type="text"
              size="small"
              @click="goEdit(scope.row)"
              >页面配置</el-button
            >

            <el-button
              v-permission="['zt.modify_zt']"
              type="text"
              size="small"
              @click="$refs.edit.handleEdit(scope.row.uuid)"
              >编辑</el-button
            >
            <el-popconfirm
              v-permission="['zt.shelf_zt']"
              :title="`确定要${scope.row.is_display ? '下架' : '上架'}该专题页吗？`"
              style="margin: 0 10px"
              @confirm="handleDisplay(scope.row)"
            >
              <el-button slot="reference" type="text" size="small">
                {{ scope.row.is_display ? "下架" : "上架" }}
              </el-button>
            </el-popconfirm>
            <el-button
              v-permission="['zt.log_zt']"
              type="text"
              size="small"
              @click="$refs.operationLog.showLogs(scope.row.uuid)"
              >日志</el-button
            >
            <el-button
              v-if="scope.row.lucky_draw"
              v-permission="['zt.view_ldd']"
              type="text"
              size="small"
              @click="$refs.prizeStatistical.handleEdit(scope.row.lucky_draw_uuid)"
              >数据查询</el-button
            >
            <el-button
              v-if="scope.row.hasLink"
              type="text"
              size="small"
              @click="goH5Page(scope.row)"
              >渠道配置</el-button
            >
            <el-popconfirm
              v-if="!scope.row.is_display"
              v-permission="['zt.delete_zt']"
              :title="`确定要删除该专题页吗？`"
              style="margin: 0 10px"
              @confirm="handleDelete(scope.row)"
            >
              <el-button slot="reference" type="text" size="small"> 删除 </el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-container">
        <pagination
          :total="count"
          :page.sync="queryParams.page"
          :limit.sync="queryParams.size"
          @pagination="fetchData"
        />
      </div>
    </el-card>

    <edit ref="edit" />
    <operation-log ref="operationLog" />
    <PrizeStatistical ref="prizeStatistical" />
  </div>
</template>

<script>
import PrizeStatistical from "../luckDraw/components/PrizeStatistical";
import SearchForm from "./components/SearchForm.vue";
import Edit from "./components/Edit.vue";
import OperationLog from "./components/OperationLog.vue";
import { getActivityList, activityDisplay, activityDelete } from "@/api/activity";
import { copyText } from "@/utils";
export default {
  components: {
    PrizeStatistical,
    SearchForm,
    Edit,
    OperationLog,
  },
  data() {
    return {
      count: 0,
      tableLoading: false,
      queryParams: {
        page: parseInt(this.$route.query.page) || 1,
        size: parseInt(this.$route.query.size) || 10,
        uuid: "",
        name: null,
        creator: null,
        platform: "H5",
        is_active: undefined,
        start_time: "",
        end_time: "",
      },
      typeArray: [{ label: "H5", value: "H5" }],
      tableData: [],
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    goH5Page(row) {
      copyText(row.link);
      process.env.NODE_ENV === "development"
        ? window.open("https://h5.kaoyan-vip.cn/admin/channel/", "_blank")
        : window.open("https://h5.kaoyanvip.cn/admin/channel/", "_blank");
    },
    goEdit(row) {
      this.$router.push(`/generator/${row.uuid}`);
    },
    async fetchData() {
      const res = await getActivityList(this.queryParams);
      this.tableData = res.data.results;
      this.count = res.data.count;
      this.$router.replace({
        path: this.$route.path,
        query: {
          ...this.queryParams,
          page: this.queryParams.page,
          size: this.queryParams.size,
        },
      });
    },
    async handleDisplay(v) {
      const data = {
        is_display: !v.is_display,
      };
      await activityDisplay(v.uuid, data);
      this.$parent.$message({
        message: v.is_display ? "下架活动成功" : "上架活动成功",
        type: "success",
      });
      this.fetchData();
    },
    async handleDelete(v) {
      console.log("v: ", v);
      // const data = {
      //   is_display: !v.is_display,
      // };
      await activityDelete(v.uuid);
      this.$parent.$message({
        message: "删除成功",
        type: "success",
      });
      this.fetchData();
    },
    timeFormatter(row) {
      return `${row.start_time} 至 ${row.end_time}`;
    },
  },
};
</script>

<style></style>
